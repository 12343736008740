<script setup>
import { computed, inject, onMounted, ref, watchEffect } from "vue";
import deviceBreakpoint from "../../../mixins/deviceBreakpoint";
import HeaderSection from "./component/HeaderSection.vue";
import DatasetAnalytics from "./component/DatasetAnalytics.vue";
import StoreTable from "./component/StoreTable.vue";
import ChartCard from "./component/ChartCard.vue";
import ColumnChartEcommerce from "./component/charts/ColumnChartEcommerce.vue";
import AreaChart from "./component/charts/AreaChart.vue";
// import CustomTable from "@/components/table/CustomTable";
import router from "../../../router";
import store from "../../../store/store";
import converter from "../../../services/currency-converter/currencyConverter";
import {
  getEcommerceSetup,
  // getProductCategories,
  topSellingProducts,
  // productType,
  orderTrend,
  revenueTrend,
  salesTrend,
  recentOrder,
} from "../../../services/ecommerce/ecommerceservice";
import DashboardLoader from "./component/DashboardLoader.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "@/gateway/backendapi";

const primarycolor = inject("primarycolor");
const { lgAndUp, xlAndUp } = deviceBreakpoint();
const products = ref(store.getters["ecommerce/getproducts"]);
const productCategories = ref(store.getters["ecommerce/getCategories"]);
const productLoading = ref(false);
const storeSetup = ref(null);
const dashboardLoading = ref(false);
const tenantID = ref("");
const recentOrderItems = ref([]);
const orderTrendData = ref([]);
const salesTrendData = ref([]);
const revenueTrendData = ref([]);
const topSellingProductItems = ref([]);

const productsheader = [
  { name: "Product name", value: "name" },
  { name: "Product price", value: "price" },
  { name: "Product Type", value: "productType" },
  { name: "Category", value: "category" },
  { name: "Status", value: "status" },
  { name: "Action", value: "action" },
];

const orderheader = [
  { name: "Product name", value: "name" },
  { name: "Product price", value: "price" },
  { name: "Status", value: "status" },
  { name: "Product Type", value: "productType" },
  { name: "Action", value: "action" },
];

const handleClick = () => {
  router.push("/tenant/store/add");
  console.log("button cliked");
};
const getRecentOrder = async () => {
  dashboardLoading.value = true;
  try {
    let response = await recentOrder();
    recentOrderItems.value = response;
    dashboardLoading.value = false;
  } catch (error) {
    dashboardLoading.value = false;
    console.error(error);
  }
};
getRecentOrder();
const salesTrendChartData = computed(() => {
  if (salesTrendData.value.length === 0) return [];
  const salesTrendItems = [];
  salesTrendData.value.forEach((item) => {
    salesTrendItems.push(Math.abs(item.sales));
  });

  // Return the transformed data
  return [
    {
      name: "Sales",
      color: "#0745AF",
      data: salesTrendItems,
    },
  ];
});
const orderTrendChartData = computed(() => {
  if (orderTrendData.value.length === 0) return [];
  const ordersTrendItems = [];
  orderTrendData.value.forEach((item) => {
    ordersTrendItems.push(Math.abs(item.orders));
  });

  // Return the transformed data
  return [
    {
      name: "Orders",
      color: "#0745AF",
      data: ordersTrendItems,
    },
  ];
});
const revenueTrendChartData = computed(() => {
  if (revenueTrendData.value.length === 0) return [];
  const revenueTrendItems = [];
  revenueTrendData.value.forEach((item) => {
    revenueTrendItems.push(Math.abs(item.revenue));
  });

  // Return the transformed data
  return [
    {
      name: "Revenue",
      color: "#0745AF",
      data: revenueTrendItems,
    },
  ];
});
const salesSeries = computed(() => {
  return salesTrendData.value.map((i) => i.month);
});
const revenueSeries = computed(() => {
  return revenueTrendData.value.map((i) => i.month);
});
const orderSeries = computed(() => {
  return orderTrendData.value.map((i) => i.month);
});
const getSalesTrend = async () => {
  dashboardLoading.value = true;
  try {
    let response = await salesTrend();
    salesTrendData.value = response;
    console.log(response, "salesTrend");
    dashboardLoading.value = false;
  } catch (error) {
    dashboardLoading.value = false;
    console.error(error);
  }
};
getSalesTrend();
const getRevenueTrend = async () => {
  dashboardLoading.value = true;
  try {
    let response = await revenueTrend();
    revenueTrendData.value = response;
    console.log(response, "revenueTrend");
    dashboardLoading.value = false;
  } catch (error) {
    dashboardLoading.value = false;
    console.error(error);
  }
};
getRevenueTrend();
const getOrderTrend = async () => {
  dashboardLoading.value = true;
  try {
    let response = await orderTrend();
    orderTrendData.value = response;
    console.log(response, "orderTrend");
    dashboardLoading.value = false;
  } catch (error) {
    dashboardLoading.value = false;
    console.error(error);
  }
};
getOrderTrend();
const getTopSellingProducts = async () => {
  dashboardLoading.value = true;
  try {
    let response = await topSellingProducts();
    topSellingProductItems.value = response;
    dashboardLoading.value = false;
  } catch (error) {
    dashboardLoading.value = false;
    console.error(error);
  }
};
getTopSellingProducts();

const getStarted = () => {
  !storeSetup.value
    ? router.push("/tenant/store/setup")
    : router.push(`/tenant/store/setup/${storeSetup.value.id}`);
};

const handleSelectionChange = (payload) => {
  console.log(payload, "he");
};

const getCategories = async () => {
  productLoading.value = true;
  store.dispatch("ecommerce/getProductCategory").then((response) => {
    productLoading.value = false;
    productCategories.value = response;
    if (products.value.length === 0) {
      allProducts();
    }
  });
};

const allProducts = async () => {
  store.dispatch("ecommerce/getAllProducts").then((response) => {
    products.value = response;
  });
};

onMounted(() => {
  if (productCategories.value.length === 0) {
    getCategories();
  }
});

// const getProductCategoriesValue = (id) =>
//   productCategories.value.find((i) => i.id === id)?.categoryName;

const navigateToViewProduct = (item) => {
  router.push(`/tenant/store/product/${item.id}`);
};

const getStoreSetup = async () => {
  dashboardLoading.value = true;
  try {
    let response = await getEcommerceSetup();
    storeSetup.value = response;
    console.log(response, 'responsesetup');
    
    dashboardLoading.value = false;
  } catch (error) {
    dashboardLoading.value = false;
    console.error(error);
  }
};
getStoreSetup();

const getUser = computed(() => {
  if (
    !store.getters.currentUser ||
    (store.getters.currentUser && Object.keys(store.getters.currentUser).length == 0)
  )
    return "";
  return store.getters.currentUser;
});

watchEffect(() => {
  if (getUser.value) {
    tenantID.value = getUser.value.tenantId;
  }
});

const memberlink = computed(() => {
  if (!tenantID.value) return "";
  return `${window.location.origin}/store/home?id=${tenantID.value}`;
});
const deleteOrder = (id) => {
  axios
    .delete(`/api/Ecommerce/DeleteOrder?orderId=${id}`)
    .then((res) => {
      console.log(res);
      ElMessage({
        type: "success",
        message: "Order deleted successfully",
        duration: 5000,
      });
      recentOrderItems.value = recentOrderItems.value.filter(
        (orderItem) => orderItem.id !== id
      );
    })
    .catch((err) => {
      if (err.response.status === 400) {
        ElMessage({
          type: "error",
          message: "Unable to delete",
          duration: 5000,
        });
      } else {
        ElMessage({
          type: "error",
          message: "Unable to delete",
          duration: 5000,
        });
      }
    });
};
const showConfirmModal = (id) => {
  ElMessageBox.confirm("Are you sure you want to proceed?", "Confirm delete", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "error",
  })
    .then(() => {
      deleteOrder(id);
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "Delete canceled",
        duration: 5000,
      });
    });
};
const deleteProduct = (id) => {
  axios
    .delete(`/api/Ecommerce/DeleteProduct?productId=${id}`)
    .then((res) => {
      console.log(res);
      ElMessage({
        type: "success",
        message: "Product deleted successfully",
        duration: 5000,
      });
      topSellingProductItems.value = topSellingProductItems.value.filter(
        (productItem) => productItem.id !== id
      );
    })
    .catch((err) => {
      if (err.response.status === 400) {
        ElMessage({
          type: "error",
          message: "Unable to delete",
          duration: 5000,
        });
      } else {
        ElMessage({
          type: "error",
          message: "Unable to delete",
          duration: 5000,
        });
      }
    });
};
const showConfirmModal2 = (id) => {
  ElMessageBox.confirm("Are you sure you want to proceed?", "Confirm delete", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "error",
  })
    .then(() => {
      deleteProduct(id);
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "Delete canceled",
        duration: 5000,
      });
    });
};

const copylink = () => {
  const textarea = document.createElement("textarea");
  textarea.value = memberlink.value;

  document.body.appendChild(textarea);

  textarea.select();
  textarea.setSelectionRange(0, 99999);

  document.execCommand("copy");
  document.body.removeChild(textarea);

  ElMessage({
    showClose: true,
    message: "URL Copied Successfully!",
    type: "success",
  });
};
</script>

<template>
  <div class="container-top" :class="{ 'container-wide': lgAndUp || xlAndUp }">
    <HeaderSection
      title="Ecommerce"
      rightbuttontext="Upload New Product"
      @handleClick="handleClick"
    >
      <template #rightbutton>
        <div class="d-flex" v-if="storeSetup">
          <el-dropdown
            trigger="click"
            class="align-items-center justify-content-center d-flex ml-md-3 ml-0 default-btn py-0 m-0 border"
            style="height: 2.2rem"
          >
            <span
              class="el-dropdown-link w-100 primary--text d-flex justify-content-between align-items-center text-center font-weight-600 p-2"
            >
             <span> More</span>
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item class="text-black" @click="copylink"
                  >Copy Store Link
                  <img class="ml-2" src="../../../assets/copyurl-icon.png" alt="" />
                </el-dropdown-item>
                <el-dropdown-item @click="getStarted"> Manage Setup </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button
            :color="primarycolor"
            class="ml-0 ml-sm-2 mt-sm-0 mt-3 w-100 header-btn"
            @click="handleClick"
            size="large"
            round
          >
            Upload new Product
          </el-button>
        </div>
      </template>
    </HeaderSection>
    <div v-if="dashboardLoading">
      <DashboardLoader />
    </div>
    <div v-else-if="!storeSetup">
      <DatasetAnalytics />
      <div class="layerparent">
        <div class="coverlayer"></div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="cardheader">
            <div
              class="d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
              <img src="../../../assets/cart_art.svg" />
              <div class="d-flex flex-column flex-md-row">
                <div class="faint_text text-center text-md-left">Home</div>
                <div class="ml-md-5 mr-md-3 text-center text-md-left faint_text">
                  Cart
                </div>
                <div class="ml-md-5 mr-md-3 text-center text-md-left faint_text">
                  All products
                </div>
              </div>
            </div>
          </div>
          <div class="cardbody">
            <h2 class="font-weight-600 text-center pt-4">Set-up your Ecommerce Store</h2>
            <div class="s-22 text-center">In few clicks</div>
            <div class="d-flex justify-content-center mt-4">
              <el-button class="get_started" @click="getStarted"> Get started </el-button>
            </div>
            <div class="d-flex justify-content-center mt-5">
              <img src="../../../assets/steps.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <DatasetAnalytics />
      <div class="row mt-5">
        <div class="col-12 col-md-8" v-loading="dashboardLoading">
          <transition name="el-fade-in-linear">
            <StoreTable
              :data="recentOrderItems"
              :headers="orderheader"
              :checkMultipleItem="false"
              @checkedrow="handleSelectionChange"
              headerText="Recent Orders"
              emptyData="No Data yet"
              :viewall="{ name: 'View all orders', route: '/tenant/store/orders' }"
            >
              <template v-slot:name="{ item }">
                <div @click="showMemberRow(item)" class="c-pointer">
                  <div class="d-flex align-items-center gap-3">
                    <img :src="item.imageURL" alt="order Image" class="order-image" />
                    <div class="ml-2">{{ item.name }}</div>
                  </div>
                </div>
              </template>
              <template v-slot:price="{ item }">
                <div @click="showMemberRow(item)" class="c-pointer">
                  {{ Math.abs(item.price).toLocaleString() }}
                </div>
              </template>
              <template v-slot:status="{ item }">
                <div @click="showMemberRow(item)" class="c-pointer">
                  {{ item.status }}
                </div>
              </template>
              <template v-slot:productType="{ item }">
                <div @click="showMemberRow(item)" class="c-pointer">
                  {{ item.productType }}
                </div>
              </template>
              <template v-slot:action="{ item }">
                <el-dropdown trigger="click">
                  <el-icon>
                    <MoreFilled />
                  </el-icon>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item>
                        <div
                          @click.prevent="showConfirmModal(item.id)"
                          class="text-color"
                        >
                          Delete
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </StoreTable>
          </transition>
        </div>
        <div class="col-12 col-md-4" v-loading="dashboardLoading">
          <ChartCard title="Sales Trend">
            <template #chart>
              <ColumnChartEcommerce
                :series="salesSeries"
                :data="salesTrendChartData"
                domId="chart1"
              />
            </template>
          </ChartCard>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-md-8" v-loading="dashboardLoading">
          <transition name="el-fade-in-linear">
            <div class="row">
              <div class="col-md-12">
                <el-skeleton :rows="5" animated v-if="dashboardLoading" />
                <StoreTable
                  :data="topSellingProductItems"
                  :headers="productsheader"
                  :checkMultipleItem="false"
                  emptyData="No Data yet"
                  @checkedrow="handleSelectionChange"
                  headerText="Top Selling Products"
                  :viewall="{
                    name: 'View all products',
                    route: '/tenant/store/products',
                  }"
                >
                  <template v-slot:name="{ item }">
                    <div @click="navigateToViewProduct(item)" class="c-pointer">
                      <div class="d-flex align-items-center gap-3">
                        <img
                          :src="item.imageURL"
                          alt="Product Image"
                          class="product-image"
                        />
                        <div class="ml-2">{{ item.name }}</div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:price="{ item }">
                    <div @click="navigateToViewProduct(item)" class="c-pointer">
                      {{ converter.numberWithCommas(item.price) }}
                    </div>
                  </template>
                  <template v-slot:status="{ item }">
                    <div @click="navigateToViewProduct(item)" class="c-pointer">
                      {{ item.status }}
                    </div>
                  </template>
                  <template v-slot:productType="{ item }">
                    <div @click="navigateToViewProduct(item)" class="c-pointer">
                      {{ item.productType }}
                    </div>
                  </template>
                  <template v-slot:category="{ item }">
                    <div @click="navigateToViewProduct(item)" class="c-pointer">
                      {{ item.category }}
                    </div>
                  </template>
                  <template v-slot:action="{ item }">
                    <el-dropdown trigger="click">
                      <el-icon>
                        <MoreFilled />
                      </el-icon>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item>
                            <div
                              @click.prevent="showConfirmModal2(item.id)"
                              class="text-color"
                            >
                              Delete
                            </div>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </StoreTable>
              </div>
            </div>
          </transition>
        </div>
        <div class="col-12 col-md-4" v-loading="dashboardLoading">
          <ChartCard title="Order Trend">
            <template #chart>
              <ColumnChartEcommerce
                :series="orderSeries"
                :data="orderTrendChartData"
                domId="chart2"
              />
            </template>
          </ChartCard>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <div class="chart-bg">
            <div class="bg-white" v-loading="dashboardLoading">
              <ChartCard title="Revenue Trend">
                <template #chart>
                  <AreaChart
                    :series="revenueSeries"
                    :data="revenueTrendChartData"
                    domId="chart3"
                  />
                </template>
              </ChartCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style setup>
.chart-bg {
  border: 1px solid rgba(206, 206, 206, 1);
  background-color: rgba(245, 245, 245, 1);
  padding: 60px;
  border-radius: 8px;
}

.product-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 3px;
}
.order-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 3px;
}

.layerparent {
  position: relative;
}

.coverlayer {
  height: 200px;
  position: absolute;
  z-index: 10000000000;
  top: -178px;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.13) 0%, #ffffff 100%);
}

.cardheader {
  background-color: #0745af;
  border-radius: 20px 20px 0px 0px;
  padding: 10px;
}

.cardbody {
  background: linear-gradient(180deg, #ededed 0%, #ffffff 100%);
}

.get_started {
  background-color: #ff5906;
  padding: 20px;
  border-radius: 60px;
  color: white;
  border: 1px solid #ff5906;
}

.get_started:hover,
.get_started:focus,
.get_started:active {
  background-color: #ff5906e3;
  padding: 20px;
  border-radius: 60px;
  color: white;
  border: 1px solid #ff5906;
}

.faint_text {
  color: #c2c2c2;
}

@media (max-width: 767px) {
  .chart-bg {
    padding: 10px;
  }
}
</style>
