<script setup>
import { defineProps, onUpdated, ref } from 'vue';
import Highcharts from "highcharts";

const props = defineProps({
  domId: {
    type: String,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
  series: {
    type: Array,
    required: true,
  },
});

const chart = ref(null)
onUpdated(() => {
    const highchartsOptions =  {
    chart: {
        type: 'area',
        renderTo: props.domId
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: props.series ? props.series : [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        allowDecimals: false,
        accessibility: {
            rangeDescription: ''
        }
    },
    yAxis: {
        title: {
            text: ''
        },
        labels: {
            enabled: true,
        },
    },
    // tooltip: {
    //     pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>' +
    //         'warheads in {point.x}'
    // },
    plotOptions: {
        area: {
            // pointStart: 1940,
            marker: {
                enabled: false,
                symbol: 'circle',
                radius: 2,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            },
            fillOpacity: .4
        }
    },
    series: props.data
}
    chart.value = new Highcharts.chart(highchartsOptions);
})

</script>

<template>
    <div class="chart-div" :id="domId" ref="chartDiv"></div>
</template>