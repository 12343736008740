<script setup>
import { defineProps, onUpdated, ref } from "vue";
import Highcharts from "highcharts";

// const props = defineProps(['domId'])
const props = defineProps({
  domId: {
    type: String,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
  series: {
    type: Array,
    required: true,
  },
});
const chart = ref(null);
onUpdated(() => {
  const highchartsOptions = {
    chart: {
      type: "column",
      renderTo: props.domId,
    },
    title: {
      text: "",
      align: "left",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: {
      categories: props.series,
      crosshair: true,
      title: {
        text:
          props && props.series
            ? props.series[0] == 1
              ? "Monthly" : "Monthly" : "",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: props.data,
    // series: [
    //   {
    //     name: "Corn",
    //     data: [387, 200, 120, 640, 540, 300],
    //   },
    //   {
    //     name: "Wheat",
    //     data: [41, 100, 10, 140, 10, 110],
    //   },
    // ],
  };
  chart.value = new Highcharts.chart(highchartsOptions);
});
</script>

<template>
  <div class="chart-div" :id="domId" ref="chartDiv"></div>
</template>
