<script setup>
import { ref } from "vue";
import { keyMetrics } from "../../../../services/ecommerce/ecommerceservice";
const dashboardMetrics = ref([]);
const dashboardLoading = ref(false);
const getKeyMetrics = async () => {
  dashboardLoading.value = true;
  try {
    let response = await keyMetrics();
    dashboardMetrics.value = response;

    dashboardLoading.value = false;
  } catch (error) {
    dashboardLoading.value = false;
    console.error(error);
  }
};
getKeyMetrics();
</script>

<template>
  <div class="row mt-2">
    <div class="col-12 col-sm-6 col-md-3 mt-4">
      <div class="cards">
        <div class="d-flex justify-content-between">
          <div class="icon-bg 'icon-bg-yellow'">
            <img src="@/assets/MoneyIcon.svg" width="20" />
          </div>
          <div>
            {{ dashboardMetrics.salesChangePercentage }}%
          </div>
        </div>
        <div class="card-type">Total Sales</div>
        <div class="font-weight-bold s-24 mt-n1">
          NGN
          {{
            dashboardMetrics && dashboardMetrics.sales
              ? Math.abs(dashboardMetrics.sales).toLocaleString()
              : 0
          }}
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mt-4">
      <div class="cards">
        <div class="d-flex justify-content-between">
          <div class="icon-bg icon-bg-blue">
            <img src="@/assets/ShoppingCart.svg" width="20" />
          </div>
          <div>
            {{ dashboardMetrics.ordersChangePercentage }}%
          </div>
        </div>
        <div class="card-type">Total orders</div>
        <div class="font-weight-bold s-24 mt-n1">
          {{
            dashboardMetrics && dashboardMetrics.orders
              ? Math.abs(dashboardMetrics.orders).toLocaleString()
              : 0
          }}
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mt-4">
      <div class="cards">
        <div class="d-flex justify-content-between">
          <div class="icon-bg icon-bg-red">
            <img src="@/assets/Cube.svg" width="20" />
          </div>
        </div>
        <div class="card-type">All Products</div>
        <div class="font-weight-bold s-24 mt-n1">
          {{
            dashboardMetrics && dashboardMetrics.products
              ? Math.abs(dashboardMetrics.products).toLocaleString()
              : 0
          }}
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mt-4">
      <div class="cards">
        <div class="d-flex justify-content-between">
          <div class="icon-bg icon-bg-green">
            <img src="@/assets/Bank.svg" width="20" />
          </div>
        </div>
        <div class="card-type">Revenue</div>
        <div class="font-weight-bold s-24 mt-n1">
          NGN
          {{
            dashboardMetrics && dashboardMetrics.revenue
              ? Math.abs(dashboardMetrics.revenue).toLocaleString()
              : 0
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cards {
  border: 1px solid #c1c1c1;
  background: #f8f8f8;
  border-radius: 15px;
  padding: 20px;
}

.icon-bg {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-bg-yellow {
  background-color: #ffc700;
}

.icon-bg-blue {
  background-color: #155cd7;
}

.icon-bg-red {
  background-color: #ff001f;
}

.icon-bg-green {
  background-color: #05ac34;
}

.performance-up {
  color: #08a53d;
  font-size: 18;
}

.performance-down {
  color: #ff5c00;
  font-size: 18;
}

.card-type {
  color: #555555;
  font-size: 15;
  margin-top: 30px;
}
</style>
